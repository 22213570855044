export const AppString = {
    USERCOLLECTION: 'userData',
    SLOTSCOLLECTION: 'eventSlots',
    BACKSTAGE: 'backStage',
    AdminStatus:'adminStatus',
    NODOCERROR: "NoDocFound",
    MEETINGROOM: 'meetingRoom',
    DASHBOARD: 'dashboard',
    VOICEQUERY:'voiceQuery'
}

export const ItemCategories = {
    PDF: 'pdf',
    IMAGES: 'images',
    VIDEOS: 'videos'
}

export const Language = {
    English: 'english',
    Hindi: 'hindi'
}

export const userLiveStatus = {
    online: 'online',
    offline: 'offline'
}

export const PlatformBased = {
    registerationCount: { label: 'Registered Users', enabled: false },
    loginCount: { label: 'Total Visitors', enabled: true },
    liveCount: { label: 'Live Count', enabled: true },
}

export const UserLocation = {
    lobby: { label: 'Lobby', enabled: true },
    orientationZone: { label: 'Introduction & Planning', enabled: true },
    stagesOfHomeBuilding: { label: 'Stages Of HomeBuilding', enabled: true },
    library: { label: 'Library', enabled: true },
    vzone: { label: 'Video Zone', enabled: true },
    usbzone: { label: 'UBS Zone', enabled: true },
    No1Spot: { label: 'No1 Spot', enabled: true },
}

export const UserLocationInital = {
    GuidedFlow_intro: { label: 'Platform Intro', enabled: true, guidedFlow: true },
    GuidedFlow_orientation: { label: 'Orientation Zone', enabled: true, guidedFlow: true },
    GuidedFlow_nukatNatak: { label: 'Nukkad Nattak', enabled: true, guidedFlow: true },
    GuidedFlow_quiz: { label: 'Quiz', enabled: true, guidedFlow: true },
    GuidedFlow_stagesOfHomeBuilding: { label: 'Stages Of HomeBuilding', enabled: true, guidedFlow: true },
}
