import React, { useContext } from 'react';
import { UserProvider } from "./components/auth/providers";
import Application from "./components/application/Application";
import { isMobileOnly } from 'react-device-detect';
import './App.css'

class App extends React.Component {

  componentDidMount() {
    if (isMobileOnly) {
      window.location.href = "/issue/index.html"
    }
  }

  render() {
    return (
      <UserProvider>
        <Application />
      </UserProvider>
    );
  }
}

export default App;
