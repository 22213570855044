import React, { Component } from "react";
import PropTypes from "prop-types";
import XLSX from 'xlsx'
import { exportFile, getEventPaddavFeedback, getEventFeedback, getEventLoginData, getRegisteredUserData, getUserAnalyticsFeedback, getRegisteredUserDataListener, getEventCallbackFeedback, getRegisteredUserDataListenerAccDate, getEventLoginDataAccDate_V2 } from "../../firebase/firebase";
import { timers } from "jquery";
import swal from 'sweetalert'
import { userLiveStatus, UserLocation, UserLocationInital } from "../../firebase/AppString";
import Chart from 'react-google-charts';
import { UserAnalyticsOrder } from "../AnalyticsData/AnalyticsOrder";

const Min_LastActiveTime = 30 * 1000;

const GraphType =
{
    BarGraph: "Bar",
    PieChart: "PieChart"
}

class Live extends Component {

    state = {
        event: null,
        count: {
        },
        loginCount: 0,
        liveCount: 0,
        registerationCount: 0,
    };

    registerationData = null;


    componentDidMount() {
        window.c = this;
        getRegisteredUserDataListenerAccDate(this.props.startDate, this.props.endDate, (err, data) => {
            // getRegisteredUserDataListenerAccDate(this.props.dateTimestamp, (err, data) => {
            if (err) {
                console.log(err);
                return
            }
            this.processRegisteredData(data);
        })
        getEventLoginDataAccDate_V2(this.props.dateString, this.processLiveEventData_V2)

    }

    getData = () => {
        getEventLoginDataAccDate_V2(this.props.dateString, this.processLiveEventData_V2)
    }

    processRegisteredData = (value) => {
        this.registerationData = value;
        let count = Object.keys(value).length;
        this.setState(prevState => ({
            registerationCount: count,
        }))
    }

    incrementObjectValue = (object, property, incrementValue = 1) => {
        if (object.hasOwnProperty(property)) {
            object[property] += incrementValue;
        } else {
            object[property] = incrementValue;
        }
    }

    processLiveEventData_V2 = (err, eventData) => {
        if (err) {
            console.log(err);
            return;
        }
        let currentTime = new Date().getTime()
        // let currentTime = 1637343198348
        if (eventData) {
            let platformCount = {}
            let loginIds = [];
            let eventCount = {}
            Object.keys(eventData).forEach(userId => {
                let userData = eventData[userId]
                if (loginIds.indexOf(userData.uid) == -1) {
                    this.incrementObjectValue(platformCount, 'loginCount', 1)
                    loginIds.push(userData.uid)
                }
                if (currentTime - userData.lastActivity_Time <= Min_LastActiveTime) {
                    this.incrementObjectValue(platformCount, 'liveCount', 1)
                    if (userData.platformLocation) {
                        this.incrementObjectValue(eventCount, userData.platformLocation, 1)
                    }
                }
            })
            console.log(loginIds)
            console.log(platformCount)
            this.setState(prevState => ({
                count: {
                    ...eventCount
                },
                loginCount: platformCount.loginCount ? platformCount.loginCount : 0,
                liveCount: platformCount.liveCount ? platformCount.liveCount : 0,
            }))
            console.log(platformCount, eventCount)
        }
    }

    processLiveEventData = (err, eventData) => {
        if (err) {
            console.log(err);
            return;
        }
        if (eventData) {
            let platformCount = {}
            let eventCount = {}
            Object.keys(eventData).forEach(userId => {
                let userData = eventData[userId]
                this.incrementObjectValue(platformCount, 'loginCount', 1)
                if (userData.state === userLiveStatus.online) {
                    this.incrementObjectValue(platformCount, 'liveCount', 1)
                    if (userData.location) {
                        this.incrementObjectValue(eventCount, userData.location, 1)
                    }
                }
            })
            this.setState(prevState => ({
                count: {
                    ...eventCount
                },
                loginCount: platformCount.loginCount ? platformCount.loginCount : 0,
                liveCount: platformCount.liveCount ? platformCount.liveCount : 0,
            }))
        }
    }

    getRegisteredUserData = () => {
        if (this.registerationData) {
            if (this.state.registerationCount > 0) {
                exportFile(Object.values(this.registerationData), "Registered User", `RegisteredUser${this.props.dateString}.xlsx`)
            } else {
                console.error("No Data!!");
                swal({
                    title: "No Data Found",
                    icon: "info",
                })
            }
        } else {
            swal({
                title: "No Data Found",
                icon: "info",
            })
        }
    }

    getFeedBackData() {
        getEventFeedback(this.props.dateString).then(feedback => {
            exportFile(Object.values(feedback), "Feedback", `Feedback${this.props.dateString}.xlsx`)
        }).catch(err => {
            swal({
                title: "No Data Found",
                icon: "info",
            })
        });;
    }

    downloadpaddavFeedBackData() {
        getEventPaddavFeedback(this.props.dateString).then(feedback => {
            exportFile(Object.values(feedback), "PadavFeedback", `PadavFeedback${this.props.dateString}.xlsx`)
        }).catch(err => {
            swal({
                title: "No Data Found",
                icon: "info",
            })
        });
    }

    downloadCallbackFeedBackData() {
        getEventCallbackFeedback(this.props.dateString).then(feedback => {
            exportFile(Object.values(feedback), "CallbackFeedback", `CallbackFeedback${this.props.dateString}.xlsx`)
        }).catch(err => {
            swal({
                title: "No Data Found",
                icon: "info",
            })
        });
    }

    downloadUserAnalyticsData() {
        getUserAnalyticsFeedback(this.props.dateString).then(data => {
            exportFile(Object.values(data), "UserAnalytics", `UserAnalytics${this.state.dateString}.xlsx`, UserAnalyticsOrder)
            // exportFile(Object.values(data), "PadavFeedback", `PadavFeedback${this.props.dateString}.xlsx`)
        }).catch(err => {
            swal({
                title: "No Data Found",
                icon: "info",
            })
        });;
    }

    data = (obj, keys) => {
        let data = [];
        keys.forEach(key => data.push(obj[key]));
        return data;
    }

    renderChartWithLimit = (type, data, xParameterName, yParameterName, maxLimit, className) => {
        let dataObjectWithOutSorting = data;

        let dataObjectWithSorting = {}
        var chartData = [
            [xParameterName, yParameterName],
        ]

        dataObjectWithSorting = Object.entries(dataObjectWithOutSorting)
            .sort(([, a], [, b]) => b - a)
            .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
        let keysArray = Object.keys(dataObjectWithSorting)
        let maxStringLength = 25;
        let others = 0
        let totalSum = 0

        keysArray.forEach((value, index) => {
            if (index < maxLimit) {
                chartData.push([`${value.length > maxStringLength ? value.substring(0, maxStringLength - 3) + "..." : value}`, dataObjectWithSorting[value]])
                totalSum += dataObjectWithSorting[value];
            } else {
                others += dataObjectWithSorting[value]
            }
        })
        if (totalSum === 0) {
            return null
        }
        return (
            <div className={`dashboard-item d-flex align-items-center justify-content-between`} style={{ width: 'unset' }}>
                <div className="seller-analytics-Chart-box">
                    <Chart
                        width={'500px'}
                        height={'300px'}
                        chartType={type}
                        loader={<div>Loading Chart</div>}
                        data={chartData}
                        className={className ? className : 'customChart'}
                        options={{
                            title: `${this.checkFor3D(type) ? xParameterName : ''}`,
                            is3D: this.checkFor3D(type),
                        }}
                    />
                </div>
            </div>
        )
    }

    checkFor3D = (type) => {
        return type === GraphType.PieChart ? true : false
    }

    render() {
        return (<>
            <div className="wrapper">

                <div className="main-container sidebar-active min-height-full">

                    <div className="dashboard__header">
                        {/* <ul className="bradcrumb">
                            <li>{this.props.date}</li>
                            <li>{this.props.week}</li>
                            <li onClick={event => this.onBackClick(event)}>{this.props.event.id.split('-')[1]}</li>
                            <li className="active"><a href="#">ANALYTICS</a></li>
                        </ul> */}
                    </div>

                    <div className="dashboard-wrapper">
                        <div className="dashboard-item__wrapper">
                            <div className={`dashboard-item d-flex align-items-center justify-content-between`}>
                                <h3 className="dashboard-item__title">Total Registration</h3>
                                <span className="dashboard-item__number">{this.state.registerationCount}</span>
                            </div>
                            {/* 
                            <div className={`dashboard-item d-flex align-items-center justify-content-between`}>
                                <h3 className="dashboard-item__title">Total Visitors</h3>
                                <span className="dashboard-item__number">{this.state.loginCount}</span>
                            </div> */}
                            <div className={`dashboard-item d-flex align-items-center justify-content-between`}>
                                <h3 className="dashboard-item__title">Repeat Users</h3>
                                <span className="dashboard-item__number">{Math.abs(this.state.loginCount - this.state.registerationCount)}</span>
                            </div>

                            <div className={`dashboard-item d-flex align-items-center justify-content-between`}>
                                <h3 className="dashboard-item__title">Live Users    </h3>
                                <span className="dashboard-item__number">{this.state.liveCount}</span>
                            </div>

                            {/* {
                                Object.keys(PlatformBased).map(key => (
                                    <div className={`dashboard-item d-flex align-items-center justify-content-between ${PlatformBased[`${key}`].enabled ? '' : 'd-none'}`}>
                                        <h3 className="dashboard-item__title">{PlatformBased[`${key}`].label}</h3>
                                        <span className="dashboard-item__number">{this.state.count[key] ? this.state.count[key] : 0}</span>
                                    </div>
                                ))
                            } */}
                        </div>
                        <div className="subheader">
                            <div className="logout_button" onClick={() => this.getData()}>
                                Reload
                            </div>
                        </div>
                        <div className="dashboard-item__wrapper">
                            {
                                Object.keys(this.state.count).length == 0 &&
                                <div className="message_no_user">
                                    No Live Users Right now.
                                </div>
                            }
                            {
                                Object.keys(this.state.count).map(key => (
                                    <div className={`dashboard-item d-flex align-items-center justify-content-between`}>
                                        <h3 className="dashboard-item__title">{key}</h3>
                                        <span className="dashboard-item__number">{this.state.count[key] ? this.state.count[key] : 0}</span>
                                    </div>
                                ))
                            }
                            {/* {
                                Object.keys(UserLocation).map(key => (
                                    <div className={`dashboard-item d-flex align-items-center justify-content-between ${UserLocation[`${key}`].enabled ? '' : 'd-none'}`}>
                                        <h3 className="dashboard-item__title">{UserLocation[`${key}`].label}</h3>
                                        <span className="dashboard-item__number">{this.state.count[key] ? this.state.count[key] : 0}</span>
                                    </div>
                                ))
                            } */}
                        </div>
                        <hr />
                        {/* <div className="dashboard-item__wrapper mg-t20">
                            {
                                Object.keys(UserLocationInital).map(key => (
                                    <div className={`dashboard-item d-flex align-items-center justify-content-between ${UserLocationInital[`${key}`].enabled ? '' : 'd-none'}`}>
                                        <h3 className="dashboard-item__title">{UserLocationInital[`${key}`].label}</h3>
                                        <span className="dashboard-item__number">{this.state.count[key] ? this.state.count[key] : 0}</span>
                                    </div>
                                ))
                            }
                        </div> */}
                        <hr />
                        <div className="dashboard-item__wrapper mg-t20" style={{ overflow: "auto" }}>
                            {
                                this.renderChartWithLimit(GraphType.BarGraph,
                                    {
                                        Total_Registeration: this.state.registerationCount,
                                        Total_Logins: this.state.loginCount,
                                        Live_Count: this.state['liveCount'] ? this.state['liveCount'] : 0
                                    }
                                    , 'users', 'count', 5, 'customChart-justbackground')
                            }
                            {
                                this.renderChartWithLimit(GraphType.PieChart, this.state.count, 'users', 'count', 5, 'customChart-justbackground')
                            }
                        </div>

                        <hr />

                        <div className="dashboard-item__wrapper pd-t60">
                            <div className="dashboard-item dashboard-item--download">
                                <h3 className="dashboard-item__title pd-t20 mg-b30">Download registration data</h3>
                                <button className="btn btn-dark btn-block" onClick={event => this.getRegisteredUserData()}>Download</button>
                            </div>
                            <div className="dashboard-item dashboard-item--download">
                                <h3 className="dashboard-item__title pd-t20 mg-b30">Download User data</h3>
                                <button className="btn btn-dark btn-block" onClick={event => this.downloadUserAnalyticsData()}>Download</button>
                            </div>
                            <div className="dashboard-item dashboard-item--download">
                                <h3 className="dashboard-item__title pd-t20 mg-b30">Download Feedback data</h3>
                                <button className="btn btn-dark btn-block" onClick={event => this.getFeedBackData()}>Download</button>
                            </div>

                            <div className="dashboard-item dashboard-item--download">
                                <h3 className="dashboard-item__title pd-t20 mg-b30">Download Paddav's Feedback data</h3>
                                <button className="btn btn-dark btn-block" onClick={event => this.downloadpaddavFeedBackData()}>Download</button>
                            </div>

                            <div className="dashboard-item dashboard-item--download">
                                <h3 className="dashboard-item__title pd-t20 mg-b30">Download CallBack Feedback data</h3>
                                <button className="btn btn-dark btn-block" onClick={event => this.downloadCallbackFeedBackData()}>Download</button>
                            </div>

                        </div>

                    </div>

                </div>
            </div>
        </>);

    }
}


export default Live;
