import React, { Component } from "react";
import './Login.css';
import { auth, signInWithId, realDB, firestore, checkForDashboardAdminId } from "../../firebase/firebase";

class Login extends Component {

    state = {
        phoneNumber: '',
        name: '',
        email: '',
        password: '',
        error: false,
        errorMessage: "",
        _loading: false,
    };

    componentDidMount = () => {
        window.showLoginError = this.showLoginError
        window.admin = true;
    }

    onInputChange = event => {
        event.preventDefault();
        let value = event.target.value
        // if (event.target.name === 'phoneNumber') {
        //     value = value.substring(0, 10)
        // }
        this.setState({ [event.target.name]: value });
    };
    showLoginError = (err) => {
        this.setState({
            error: true,
            errorMessage: err.message ? err.message : err
        });
    }
    validateEmail = (email) => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }
    onFormSubmit = async (event) => {
        event.preventDefault();
        try {
            if (window.gtag_report_conversion) {
                window.gtag_report_conversion()
              }
              if (window.fbq) {
                window.fbq('track', 'Lead_BGK')
              }
            // let Id = this.state.phoneNumber;
            // if (this.state.phoneNumber.length !== 10) {
            //     this.setState({
            //         error: true,
            //         errorMessage: "Enter Valid Phone Number"
            //     });
            //     return;
            // }
            // const userData = await this.checkInRegisteredNumber(Id);
            // window.userDataFirebase = userData;   
            let email = this.state.email.toLowerCase();
            let pass = this.state.password;
            await checkForDashboardAdminId(email);
            signInWithId(
                email,
                pass,
                () => { this.setState({ error: false }); },
                (error) => {
                    // var errorCode = error.code;
                    // if (errorCode === "auth/user-not-found") {
                    //     this.signupWithEmail(email, pass);
                    //     return;
                    // }
                    this.setState({ error: true, errorMessage: "User ID and password do not match" });
                }
            );
        }
        catch (err) {
            console.log(err)
            if (err.code !== "auth/user-not-found") {
                this.setState({
                    error: true,
                    errorMessage: err.message ? err.message : err
                });
            }
        }
    };

    signupWithEmail = (email, password) => {
        auth.createUserWithEmailAndPassword(email, password)
            .then(function (response) {
                console.log(response.user.email + " is logged in right now");
                response.user.updateProfile({
                    displayName: window.userDataFirebase.name
                }).then(() => {
                    console.log("updated Name!!");
                })
            })
            .catch(function (error) {
                var errorCode = error.code;
                var errorMessage = error.message;
                console.log("code: " + errorCode + " & ErrorMsg: " + errorMessage);
            });
    }

    checkInRegisteredNumber = (phoneNumber) => {
        return new Promise(async (resolve, reject) => {
            try {
                const docRef = firestore.collection("userData").where('phoneNumber', '==', `${phoneNumber}`);
                const query = await docRef.get()
                if (query.empty) {
                    let error =
                    {
                        code: "UserNotFound",
                        message: "User is not registered"
                    }
                    throw error;
                }
                resolve(query.docs[0].data());
            } catch (err) {
                console.log(err)
                reject(err)
            }
        })

    }

    checkEventSlotStatus = (slotId) => {
        return new Promise(async (resolve, reject) => {
            try {
                const docRef = firestore.collection("eventSlots").doc(`${slotId}`);
                const doc = await docRef.get()
                if (!doc.exists) {
                    let error =
                    {
                        code: "SoSuchSlotFound",
                        message: "Please come back later."
                    }
                    throw error;
                }
                let data = doc.data();
                window.slotData = data;
                if (!(data.dayActive && data.active)) {
                    let error =
                    {
                        code: "EventNotActive",
                        message: "Please come back later, Event has been closed"
                    }
                    throw error;
                }
                var currDateLocal = new Date();
                currDateLocal = currDateLocal.getTime() + this.props.webTimeDiff;
                let diff = (data.eventStartTime - currDateLocal) / 60000;

                if (diff > 0) {
                    let slotTime = new Date(data.eventStartTime)
                    slotTime = slotTime.toLocaleTimeString('en-US')
                    let error =
                    {
                        code: "EventIsNotLiveYet",
                        message: `Event will be live at ${slotTime}.`
                    }
                    throw error;
                }
                else if (diff < 0 && diff > -120) {
                    console.log("can login");
                    resolve();
                }
                else {
                    console.log(diff + 120);
                    let error =
                    {
                        code: "EventFinished",
                        message: `Event ended as per your time slot, Thankyou.`
                    }
                    throw error;
                }

            } catch (err) {
                reject(err)
            }
        })
    }

    checkUserStatus = async (userId) => {
        return new Promise((resolve, reject) => {
            if (userId == undefined) {
                reject({
                    code: "UserIDWasNull",
                    message: "Please try again later"
                });
            } else {
                if (userId === "") {
                    reject({
                        code: "UserIDWasNull",
                        message: "Please try again later"
                    });
                }
            }
            var userCheck = realDB.ref('loggedInUser/' + userId)
            userCheck.once('value').then((snapshot) => {
                if (snapshot.exists()) {
                    let data = snapshot.val();
                    if (data.state === 'online') {
                        let err =
                        {
                            code: "AlreadyLogged",
                            message: "User Logged in from another system."
                        }
                        reject(err);
                    } else {
                        resolve();
                    }
                } else {
                    resolve();
                }
            }, (err) => {
                reject(err);
            })
        })
    }

    render() {


        if (this.state._loading) {
            return <img alt="loading" src="/images/loader.gif" />;
        }
        return (
            <section className="landing-page min-height-full">
                <aside className="landing-pageBox d-flex justify-content-between align-items-start min-height-full image-bg" style={{ backgroundImage: `url(/assets/images/bg/home-bg.jpg)` }}>
                    <a className="landing-pageBox__logo">
                        <img src="/assets/images/logoAB.png" alt="Ultra Tech" />
                    </a>
                    <img src="/assets/images/side-pic.png" alt="Ultra Tech" className="landing-pageBox__banner" />
                </aside>
                <aside className="signinBox min-height-full">
                    <form onSubmit={this.onFormSubmit}>
                        <h1 className="signinBox__heading">Dashboard Sign in</h1>
                        <div className="signinBox__body pd-t70">
                            <div className="form-group mg-b50">
                                <input type="text" className="form-control" name="email" value={this.state.email} placeholder="*Enter Email" onChange={this.onInputChange} required={true} />
                            </div>
                            <div className="form-group mg-b50">
                                <input type="password" className="form-control" name="password" value={this.state.password} placeholder="*Enter Password" onChange={this.onInputChange} required={true} />
                            </div>
                            {this.state.error && <div className="mg-b50" style={{ color: "red", fontSize: "1.25rem" }}>{this.state.errorMessage}</div>}
                            <div className="text-center">
                                <button className="btn btn-lg btn-dark" disabled={this.state.email.length > 2 && this.state.password.length > 2 ? false : true}><span className="d-flex justify-content-between">SIGN IN <i className="icon-angle-forward"></i></span></button>
                            </div>
                        </div>
                    </form>
                </aside>
            </section>
        );
    }

}

export default Login;
