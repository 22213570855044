import { firestore } from "../../firebase/firebase"
import React, { Component } from "react";
import PropTypes from "prop-types";
import XLSX from 'xlsx'
import { exportFile, 
        getEventPaddavFeedback, 
        getEventFeedback, 
        getUserAnalyticsFeedback, 
        getEventCallbackFeedback, 
        getRegisteredUserDataListenerAccDate, 
        removeRegisterationListener,
        getVoiceQueryDataListenerAccDate 
    } from "../../firebase/firebase";
import { timers } from "jquery";
import swal from 'sweetalert'
import { userLiveStatus, UserLocation, UserLocationInital } from "../../firebase/AppString";
import Chart from 'react-google-charts';
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css';
import ReactAudioPlayer from "react-audio-player";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
const GraphType =
{
    BarGraph: "Bar",
    PieChart: "PieChart"
}

export default class VoiceQuery extends Component {

    state = {
        event: null,
        count: {
        },
        loginCount: 0,
        registerationCount: 0,
        dateString: this.props.dateString,
        calendarValue: null,
        loading: false,
    };

    registerationData = null;



    //#region functions
    processRegisteredData = (value) => {
        this.registerationData = value;
       // console.log(value)
        let count = Object.keys(value).length;
        console.log(count)
        this.setState(prevState => ({
            loading:false,
            registerationData: this.registerationData,
        }))
    }

    // incrementObjectValue = (object, property, incrementValue = 1) => {
    //     if (object.hasOwnProperty(property)) {
    //         object[property] += incrementValue;
    //     } else {
    //         object[property] = incrementValue;
    //     }
    // }

    // getRegisteredUserData = () => {
    //     if (this.registerationData) {
    //         if (this.state.registerationCount > 0) {
    //             exportFile(Object.values(this.registerationData), "Registered User", `RegisteredUser${this.state.dateString}.xlsx`)
    //         } else {
    //             //console.error("No Data!!");
    //             swal({
    //                 title: "No Data Found",
    //                 icon: "info",
    //             })
    //         }
    //     } else {
    //         swal({
    //             title: "No Data Found",
    //             icon: "info",
    //         })
    //     }
    // }

    // getFeedBackData() {
    //     getEventFeedback(this.state.dateString).then(feedback => {
    //         exportFile(Object.values(feedback), "Feedback", `Feedback${this.state.dateString}.xlsx`)
    //     }).catch(err => {
    //         swal({
    //             title: "No Data Found",
    //             icon: "info",
    //         })
    //     });;
    // }

    // downloadpaddavFeedBackData() {
    //     getEventPaddavFeedback(this.state.dateString).then(feedback => {
    //         exportFile(Object.values(feedback), "PadavFeedback", `PadavFeedback${this.state.dateString}.xlsx`)
    //     }).catch(err => {
    //         swal({
    //             title: "No Data Found",
    //             icon: "info",
    //         })
    //     });
    // }

    // downloadCallbackFeedBackData() {
    //     getEventCallbackFeedback(this.state.dateString).then(feedback => {
    //         exportFile(Object.values(feedback), "CallbackFeedback", `CallbackFeedback${this.state.dateString}.xlsx`)
    //     }).catch(err => {
    //         swal({
    //             title: "No Data Found",
    //             icon: "info",
    //         })
    //     });
    // }

    // downloadUserAnalyticsData() {
    //     getUserAnalyticsFeedback(this.state.dateString).then(data => {
    //         exportFile(Object.values(data), "PadavFeedback", `PadavFeedback${this.state.dateString}.xlsx`)
    //     }).catch(err => {
    //         swal({
    //             title: "No Data Found",
    //             icon: "info",
    //         })
    //     });;
    // }

    data = (obj, keys) => {
        let data = [];
        keys.forEach(key => data.push(obj[key]));
        return data;
    }

    // renderChartWithLimit = (type, data, xParameterName, yParameterName, maxLimit, className) => {
    //    // console.log(data)
    //     let dataObjectWithOutSorting = data;

    //     let dataObjectWithSorting = {}
    //     var chartData = [
    //         [xParameterName, yParameterName],
    //     ]

    //     dataObjectWithSorting = Object.entries(dataObjectWithOutSorting)
    //         .sort(([, a], [, b]) => b - a)
    //         .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
    //     let keysArray = Object.keys(dataObjectWithSorting)
    //     let maxStringLength = 25;
    //     let others = 0
    //     let totalSum = 0

    //     keysArray.forEach((value, index) => {
    //         if (index < maxLimit) {
    //             chartData.push([`${value.length > maxStringLength ? value.substring(0, maxStringLength - 3) + "..." : value}`, dataObjectWithSorting[value]])
    //             totalSum += dataObjectWithSorting[value];
    //         } else {
    //             others += dataObjectWithSorting[value]
    //         }
    //     })
    //     if (totalSum === 0) {
    //         return null
    //     }
    //     return (
    //         <div className={`dashboard-item d-flex align-items-center justify-content-between`} style={{ width: 'unset' }}>
    //             <div className="seller-analytics-Chart-box">
    //                 <Chart
    //                     width={'500px'}
    //                     height={'300px'}
    //                     chartType={type}
    //                     loader={<div>Loading Chart</div>}
    //                     data={chartData}
    //                     className={className ? className : 'customChart'}
    //                     options={{
    //                         title: `${this.checkFor3D(type) ? xParameterName : ''}`,
    //                         is3D: this.checkFor3D(type),
    //                     }}
    //                 />
    //             </div>
    //         </div>
    //     )
    // }

    // checkFor3D = (type) => {
    //     return type === GraphType.PieChart ? true : false
    // }
    //#endregion


    hanldeCalanedarSearch = (event) => {
        if (event) {
            event.preventDefault();
        }
        if(this.state.calendarValue !== null){
            let date = new Date(this.state.calendarValue)
     
            let dateString = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`
            
            let startDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
            let endDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()+1}`
           
    
            this.setState({
                dateString,
                loading: true
            })
            this.registerationData=null;
            removeRegisterationListener();
            getVoiceQueryDataListenerAccDate(startDate, endDate, (err, data) => {
                if (err) {
                    //console.log(err);
                    return
                }
                //console.log(data)
                this.setState({
                    loading: false
                })
                this.processRegisteredData(data);
            })
        } else {
            swal({
                title: "Please choose a date",
                icon: "info",
            })
        }
        
    }

    render() {
     
        
        return (<>
            {
                this.state.loading &&
                <div className="loaderContainer">
                    <img alt="loader" src="/assets/images/loader.gif" className="loaderImage" />
                </div>
            }
            <div className="wrapper">

                <div className="main-container sidebar-active min-height-full">
                    <div className="dashboard__header">

                    </div>

                    <div className="calendar-container">
                        <Calendar
                            onChange={(event) => {
                                this.setState({
                                    calendarValue: event
                                });
                            }}
                            value={this.state.calendarValue}
                        />
                        <div className="calendar-btn" onClick={(e) => this.hanldeCalanedarSearch(e)}>Search</div>
                    </div>
                    <hr />
                    <div>
                        {this.state.registerationData &&  this.state.dateString}
                    </div>
                    <div className="" style={{height:"200px",overflow: "auto"}}>
                        {/* <div className="dashboard-item__wrapper">
                            <div className={`dashboard-item d-flex align-items-center justify-content-between`}>
                                <h3 className="dashboard-item__title">Registered Users</h3>
                                <span className="dashboard-item__number">{this.state.registerationCount}</span>
                            </div>
                        </div>
                        <hr /> */}
                          { this.state.registerationData &&     
                          <table style={{width:"100%", fontSize:"15px", height:"300px"}}>
                            <thead>
                              <tr>
                                <th style={{textAlign:"left"}}>Name</th>
                                <th style={{textAlign:"left"}}>Number</th>
                                <th style={{textAlign:"left"}}>Timestamp</th>
                                <th style={{textAlign:"left"}}>State</th>
                                <th style={{textAlign:"left"}}>Audio</th>
                              </tr>
                            </thead>
                            <tbody>
                    {
                       Object.keys(this.state.registerationData).map((value, key) => {
                            return (
                              
                           <>
                              <tr>
                                <td>{this.state.registerationData[value].name}</td>
                                <td>{this.state.registerationData[value].phoneNumber}</td>
                                <td style={{width:"30%", paddingBottom:"20px"}}>{this.state.registerationData[value].timeStamp}</td>
                                <td>{this.state.registerationData[value].state}</td>
                                <td style={{width:"30rem"}}> 
                                    <ReactAudioPlayer
                                        //src="/assets/ambientMusic.mp3"
                                        src={this.state.registerationData[value].link}
                                        autoPlay={false}
                                        volume={0.1}
                                        loop={false}
                                        preload="auto"
                                        controls
                                        style={{width:"100%", height:"2rem"}}
                                       // ref={this.correctAudioRef}
                                    />
                                </td>
                              </tr>
                              
                              </>
                           
                              );
                            })
                        
                          }
                         </tbody>
                          </table> 
                          
                        }
                    </div>

                </div>
            </div>
        </>);

    }
}

