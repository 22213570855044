import React, { Component } from "react";
import { UserContext } from "../auth/providers";
import { attachAdminListener, getCurrentTimeStamp, List, signOut, toggleAdminListener, togglePlatformListener } from "../firebase/firebase";
import AnalyticsData from "./AnalyticsData/AnalyticsData";
import VoiceQuery from "./VoiceQuery/VoiceQuery";

import Cms from "./cms/Cms";
import Event from "./event/Event";
import Live from "./Live/Live";

const menuItems = [
    { id: 1, name: "Resources Upload" },
    { id: 2, name: "Event Slots" },
    { id: 3, name: "LIVE" },
    { id: 4, name: 'Analytics Data' },
    { id: 5, name: 'Voice Notes Data' }
];

class Home extends Component {

    state = {
        menuItems: menuItems,
        activeMenu: menuItems[0],
        showLoader: false,
        currentData: null,
        expertListAttached: false,
        expertLiveToggle: false,
        platformLiveToggle: false,
    }

    componentDidMount() {
        getCurrentTimeStamp().then(({ dateString, currDateWeb, startDate, endDate }) => {
            console.log(dateString)
            this.setState({
                currentData: dateString,
                currDateWeb: currDateWeb,
                startDate, endDate,
            })
        })
        List()

        attachAdminListener((err, data) => {
            if (err) {
                console.log(err)
                return
            }
            this.setState({
                expertListAttached: true,
                expertLiveToggle: data.adminLive,
                platformLiveToggle: data.platformLive,
            })
        })
    }

    setActiveMenu = (event, menu) => {
        event.preventDefault();

        this.setState({ activeMenu: menu });
    }

    toggleLoader = (value) => {
        this.setState({
            showLoader: value
        })
    }


    logout = (event) => {
        if (event)
            event.preventDefault()
        signOut()
    }


    render() {
        return (
            <>
                <div className="wrapper">

                    <header className="header">
                        <h1 className="header__logo"><img src="assets/images/logo.png" alt="" /></h1>


                        <div className="header_side">
                            {
                                this.state.expertListAttached &&
                                <>
                                    <div className="header_input_title">
                                        <h2> Expert Live Toggle</h2>
                                        <label className="custom-toggle">
                                            <input type="checkbox"
                                                onChange={event =>
                                                    toggleAdminListener(event.target.checked)
                                                }
                                                checked={this.state.expertLiveToggle}
                                            />
                                            <div className="slider round"></div>
                                        </label>
                                    </div>

                                    <div className="header_input_title">
                                        <h2> Platform Live Toggle</h2>
                                        <label className="custom-toggle">
                                            <input type="checkbox"
                                                onChange={event =>
                                                    togglePlatformListener(event.target.checked)
                                                }
                                                checked={this.state.platformLiveToggle}
                                            />
                                            <div className="slider round"></div>
                                        </label>
                                    </div>
                                </>
                            }


                            <div className="logout_button" onClick={(e) => this.logout(e)}>LogOut</div>
                        </div>

                    </header>

                    <aside className="sidebar">
                        <ul className="sidebar-menu">
                            {
                                this.state.menuItems.map(menu =>
                                    <li key={menu.id} onClick={event => this.setActiveMenu(event, menu)}>
                                        <a className={this.state.activeMenu.id == menu.id ? "active" : ""} href="">{menu.name}</a>
                                    </li>
                                )
                            }
                        </ul>
                    </aside>

                    {
                        this.state.activeMenu.id == 1 &&
                        <Cms toggleLoader={this.toggleLoader}></Cms>
                    }

                    {
                        this.state.activeMenu.id == 2 &&
                        <Event toggleLoader={this.toggleLoader} ></Event>
                    }
                    {
                        this.state.activeMenu.id === 3 &&
                        <>
                            {this.state.currentData ?
                                <>
                                    <Live dateString={this.state.currentData} startDate={this.state.startDate} endDate={this.state.endDate}></Live>
                                    {/* <Live dateString={"19-11-2021"} startDate={"2021-11-19"} endDate={"2021-11-20"}></Live> */}
                                </>
                                :
                                <>
                                    <div className="loaderContainer">
                                        <img alt="loader" src="/assets/images/loader.gif" className="loaderImage" />
                                    </div>
                                </>}
                        </>
                    }
                    {
                        this.state.activeMenu.id === 4 &&
                        <>
                            {this.state.currentData ?
                                <>
                                    <AnalyticsData dateString={this.state.currentData} startDate={this.state.startDate} endDate={this.state.endDate}></AnalyticsData>
                                </>
                                :
                                <>
                                    <div className="loaderContainer">
                                        <img alt="loader" src="/assets/images/loader.gif" className="loaderImage" />
                                    </div>
                                </>}
                        </>
                    }
                    {
                        this.state.activeMenu.id === 5 &&
                        <>
                            {this.state.currentData ?
                                <>
                                    <VoiceQuery dateString={this.state.currentData} startDate={this.state.startDate} endDate={this.state.endDate}></VoiceQuery>
                                </>
                                :
                                <>
                                    <div className="loaderContainer">
                                        <img alt="loader" src="/assets/images/loader.gif" className="loaderImage" />
                                    </div>
                                </>}
                        </>
                    }
                    {
                        this.state.showLoader &&
                        <div className="loaderContainer">
                            <img alt="loader" src="/assets/images/loader.gif" className="loaderImage" />
                        </div>
                    }
                </div>

            </>
        );
    }

}
Home.contextType = UserContext;

export default Home;
